<template>
  <div class="left-bar" :style="{ width: !collapsed ? '180px' : '80px' }">
    <a-menu mode="inline" theme="dark" :selectedKeys="[curKey]" :inline-collapsed="collapsed">
      <a-sub-menu :key="menu.path" v-for="(menu, index) in headerNav">
        <!-- <span slot="title">
					<img src="@/assets/menu.png">
				</span> -->
        <template #icon><img src="@/assets/menu.png" /></template>
        <template #title>
          <img :src="men[menu.path]" style="margin-right: 10px; width: 18px; height: 18px" />
          <span v-if="!collapsed">{{ menu.name }}</span>
        </template>
        <a-menu-item :key="item.path" v-for="item in curLeftMenu[index]">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <a-button type="primary" style="margin-bottom: 16px" @click="toggleCollapsed" class="btn_375">
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
    </a-button>
  </div>
</template>

<script>
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3676972_1ugw0jc888.js', // 在 iconfont.cn 上生成
})
export default {
  name: 'sidebar',
  data() {
    return {
      men: {},
      collapsed: false,
    }
  },
  computed: {
    curKey() {
      return this.$route.fullPath
    },
    curLeftMenu() {
      let a = -1
      let par = this.$route.meta.parent
      console.log('par::', par)
      let sidebarList = this.$store.state.sidebarList
      console.log('curLeftMenu::', sidebarList)
      return sidebarList
    },
    headerNav() {
      return this.$store.state.headerNav
    },
  },

  comments: {
    'my-icon': MyIcon,
  },
  watch: {},
  created() {
    this.men = {
      '/service': require('@/assets/sidebar/业务管理.png'),
      '/user': require('@/assets/sidebar/用户管理.png'),
      '/content': require('@/assets/sidebar/内容管理.png'),
      '/device': require('@/assets/sidebar/设备管理.png'),
      '/permision': require('@/assets/sidebar/员工管理.png'),
      '/hospital': require('@/assets/sidebar/定点医院管理.png'),
      '/account': require('@/assets/sidebar/对账管理.png'),
      '/gateway': require('@/assets/sidebar/网关管理.png'),
      '/IotCard': require('@/assets/sidebar/物联卡.png'),
    }
    const userAgent = navigator.userAgent
    // 匹配设备型号信息
    const modelRegex =
      /(Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini)/

    const match = userAgent.match(modelRegex)
    if (match) {
      this.collapsed = true
    }
  },
  mounted() { },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="less">
.left-bar {
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  width: 180px;
  background: #1b2028;
  box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
  z-index: 1;

  .btn_375 {
    display: none;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)
export const routes = [
	{
		path: '/fireware',
		component: () => import('@/view/gateway/fireware'),
		name: 'Fireware',
		meta: {
			title: '固件管理',
			noCache: true,
			permission: '',
			parent: '网关管理'
		}
	},
	{
		path: '/firewarePush',
		component: () => import('@/view/gateway/firewarePush'),
		name: 'FirewarePush',
		meta: {
			title: '固件推送',
			noCache: true,
			permission: '',
			parent: '网关管理'
		}
	},
	{
		path: '/iotcard',
		component: () => import('@/view/iotCard/iotcard'),
		name: 'Iotcard',
		meta: {
			title: '物联网卡',
			noCache: true,
			permission: '',
			parent: '物联网卡'
		}
	},
	{
		path: '/rentList',
		component: () => import('@/view/service/rentList'),
		name: 'RentList',
		meta: {
			title: '设备租赁列表',
			noCache: true,
			permission: '',
			parent: '业务管理'
		}
	},
	{
		path: '/orderList',
		component: () => import('@/view/service/orderList'),
		name: 'OrderList',
		meta: {
			title: '订单管理',
			noCache: true,
			permission: '',
			parent: '业务管理'
		}
	},
	{
		path: '/activityOrderList',
		component: () => import('@/view/service/activityOrderList'),
		name: 'ActivityOrderList',
		meta: {
			title: '试用订单',
			noCache: true,
			permission: '',
			parent: '业务管理'
		}
	},
	{
		path: '/commentList',
		component: () => import('@/view/service/commentList'),
		name: 'CommentList',
		meta: {
			title: '留言管理',
			noCache: true,
			permission: '',
			parent: '业务管理'
		}
	},
	{
		path: '/userList',
		component: () => import('@/view/user/userList'),
		name: 'UserList',
		meta: {
			title: '用户列表',
			noCache: true,
			permission: '',
			parent: '用户管理'
		}
	},
	{
		path: '/deviceList',
		component: () => import('@/view/device/deviceList'),
		name: 'DeviceList',
		meta: {
			title: '设备清单',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/unbindList',
		component: () => import('@/view/device/unbindList'),
		name: 'UnbindList',
		meta: {
			title: '待绑定设备',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/recoveryList',
		component: () => import('@/view/device/recoveryList'),
		name: 'RecoveryList',
		meta: {
			title: '回收站',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/onlineDevice',
		component: () => import('@/view/device/onlineDevice'),
		name: 'OnlineDevice',
		meta: {
			title: '在线设备',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/help',
		component: () => import('@/view/content/help'),
		name: 'Help',
		meta: {
			title: '帮助指南',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/private',
		component: () => import('@/view/content/private'),
		name: 'Private',
		meta: {
			title: '隐私管理',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/aboutUs',
		component: () => import('@/view/content/aboutUs'),
		name: 'AboutUs',
		meta: {
			title: '关于我们',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/pay',
		component: () => import('@/view/content/pay'),
		name: 'pay',
		meta: {
			title: '支付协议',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/advert',
		component: () => import('@/view/content/advert'),
		name: 'Advert',
		meta: {
			title: '广告位',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/advertEdit',
		component: () => import('@/view/content/advertEdit'),
		name: 'AdvertEdit',
		meta: {
			title: '广告编辑',
			noCache: true,
			permission: '',
			parent: '广告编辑'
		}
	},
	{
		path: '/hospitalList',
		component: () => import('@/view/hospital/hospitalList'),
		name: 'Hospital',
		meta: {
			title: '医院列表',
			noCache: true,
			permission: '',
			parent: '医院管理'
		}
	},
	{
		path: '/departmentList',
		component: () => import('@/view/hospital/departmentList'),
		name: 'DepartmentList',
		meta: {
			title: '科室列表',
			noCache: true,
			permission: '',
			parent: '医院管理'
		}
	},
	{
		path: '/charging',
		component: () => import('@/view/hospital/charging'),
		name: 'Charging',
		meta: {
			title: '计费方式',
			noCache: true,
			permission: '',
			parent: '医院管理'
		}
	},
	{
		path: '/account',
		component: () => import('@/view/account/account'),
		name: 'Account',
		meta: {
			title: '对账账户',
			noCache: true,
			permission: '',
			parent: '对账管理'
		}
	},
	{
		path: '/orderFlowing',
		component: () => import('@/view/account/orderFlowing'),
		name: 'OrderFlowing',
		meta: {
			title: '订单流水',
			noCache: true,
			permission: '',
			parent: '对账管理'
		}
	},
	{
		path: '/withdrawalConfig',
		component: () => import('@/view/account/withdrawalConfig'),
		name: 'WithdrawalConfig',
		meta: {
			title: '提现设置',
			noCache: true,
			permission: '',
			parent: '对账管理'
		}
	},
	{
		path: '/withdrawalList',
		component: () => import('@/view/account/withdrawalList'),
		name: 'WithdrawalList',
		meta: {
			title: '提现管理',
			noCache: true,
			permission: '',
			parent: '对账管理'
		}
	}
]

export const adminRoutes = [
	{
		path: '/jueseList',
		component: () => import('@/view/permision/jueseList'),
		name: 'jueseList',
		meta: {
			title: '角色列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/yuangongList',
		component: () => import('@/view/permision/yuangongList'),
		name: 'YuangongList',
		meta: {
			title: '员工列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/yuangongInfo',
		component: () => import('@/view/permision/yuangongInfo'),
		name: 'YuangongInfo',
		meta: {
			title: '员工编辑',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	}
]
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '康皇科技智能平台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
}
const threeRoutes = []
export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '康皇科技智能平台-后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/h5',
		component: () => import('@/view/h5/h5Layout.vue'),
		name: 'h5',
		redirect: '/h5/index',

		meta: {
			title: '康皇科技智能平台-移动端后台',
			icon: 'location'
		},
		children: [
			{
				path: 'index',
				component: () => import('@/view/h5/index.vue'),
				name: 'h5Index',
				meta: {
					title: '康皇后台-移动端',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			},
			{
				path: 'deviceList',
				component: () => import('@/view/h5/deviceList/index.vue'),
				name: 'h5DeviceList',
				meta: {
					title: '设备清单',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			},
			{
				path: 'orderList',
				component: () => import('@/view/h5/orderList/index.vue'),
				name: 'h5OrderList',
				meta: {
					title: '订单管理',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			}]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	}
	// {
	// 	path: '/aboutInfo',
	// 	component: () => import('@/view/aboutInfo'),
	// 	name: 'AboutInfo',
	// 	meta: {
	// 		title: '关于我们'
	// 	}
	// },
	// {
	// 	path: '/webview',
	// 	component: () => import('@/view/webview'),
	// 	name: 'Webview',
	// 	meta: {
	// 		title: '隐私服务'
	// 	}
	// },
	// {
	// 	path: '/policyInfo',
	// 	component: () => import('@/view/policyInfo'),
	// 	name: 'PolicyInfo',
	// 	meta: {
	// 		title: '政策详情'
	// 	}
	// }
]
const createRouter = () =>
	new Router({
		mode: 'history', // require service support
		base: '/',
		scrollBehavior(to, from, savedPosition) {
			console.log('路由滚动', savedPosition)
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router

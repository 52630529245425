let headerNav = [
	{
		name: '业务管理',
		path: '/service',
		home: false,
	},
	{
		name: '用户管理',
		path: '/user',
		home: false,

	},
	{
		name: '设备管理',
		path: '/device',
		home: false,
	},
	{
		name: '内容管理',
		path: '/content',
		home: false,
	},
	{
		name: '员工管理',
		path: '/permision',
		home: false,
	},
	{
		name: '医院管理',
		path: '/hospital',
		home: false,
	},
	{
		name: '对账管理',
		path: '/account',
		home: false,
	},
	{
		name: '固件管理',
		path: '/gateway',
		home: false,
	},
	{
		name: '物联网卡',
		path: '/IotCard',
		home: true,

	}
]

let sidebarList = [
	[
		{
			name: '设备租赁清单',
			path: '/rentList',
			parent: '业务管理'
		},
		{
			name: '订单管理',
			path: '/orderList',
			parent: '业务管理'
		},
		{
			name: '试用订单',
			path: '/activityOrderList',
			parent: '业务管理'
		},
		// {
		// 	name: '留言管理',
		// 	path: '/commentList',
		// 	parent: '业务管理'
		// }
	],
	[
		{
			name: '用户列表',
			path: '/userList',
			parent: '用户管理'
		}
	],
	[
		{
			name: '设备清单',
			path: '/deviceList',
			parent: '设备管理'
		},
		{
			name: '待绑定设备',
			path: '/unbindList',
			parent: '设备管理'
		},
		{
			name: '联网设备',
			path: '/onlineDevice',
			parent: '设备管理'
		}
	],
	[
		{
			name: '帮助指南',
			path: '/help',
			parent: '内容管理'
		},
		{
			name: '隐私管理',
			path: '/private',
			parent: '内容管理'
		},
		{
			name: '关于我们',
			path: '/aboutUs',
			parent: '内容管理'
		},
		{
			name: '支付协议',
			path: '/pay',
			parent: '内容管理'
		},
		// {
		// 	name: '广告位',
		// 	path: '/advert',
		// 	parent: '内容管理'
		// }
	],
	[
		{
			name: '员工列表',
			path: '/yuangongList',
			parent: '员工管理'
		},
		{
			name: '角色列表',
			path: '/jueseList',
			parent: '员工管理'
		}
	],
	[
		{
			name: '医院列表',
			path: '/hospitalList',
			parent: '医院管理'
		},
		{
			name: '计费方式',
			path: '/charging',
			parent: '医院管理'
		}
	],
	[
		{
			name: '订单流水',
			path: '/orderFlowing',
			parent: '对账管理'
		},
		{
			name: '提现设置',
			path: '/withdrawalConfig',
			parent: '对账管理'
		},
		{
			name: '提现管理',
			path: '/withdrawalList',
			parent: '对账管理'
		},
		{
			name: '对账用户',
			path: '/account',
			parent: '对账管理'
		}
	],
	[
		{
			name: '固件管理',
			path: '/fireware',
			parent: '固件管理'
		},
		{
			name: '固件推送',
			path: '/firewarePush',
			parent: '固件管理'
		}
	],
	[
		{
			name: '物联网卡',
			path: '/iotcard',
			parent: '物联网卡'
		}
	],
]
let sidebarList2 = [
	[
		{
			name: '设备租赁清单',
			path: '/rentList',
			parent: '业务管理'
		},
		{
			name: '订单管理',
			path: '/orderList',
			parent: '业务管理'
		},
		{
			name: '试用订单',
			path: '/activityOrderList',
			parent: '业务管理'
		},
		// {
		// 	name: '留言管理',
		// 	path: '/commentList',
		// 	parent: '业务管理'
		// }
	],
	[
		{
			name: '用户列表',
			path: '/userList',
			parent: '用户管理'
		}
	],
	[
		{
			name: '设备清单',
			path: '/deviceList',
			parent: '设备管理'
		},
		{
			name: '待绑定设备',
			path: '/unbindList',
			parent: '设备管理'
		},
		{
			name: '联网设备',
			path: '/onlineDevice',
			parent: '设备管理'
		}
	],
	[
		{
			name: '帮助指南',
			path: '/help',
			parent: '内容管理'
		},
		{
			name: '隐私管理',
			path: '/private',
			parent: '内容管理'
		},
		{
			name: '关于我们',
			path: '/aboutUs',
			parent: '内容管理'
		},
		{
			name: '支付协议',
			path: '/pay',
			parent: '内容管理'
		}
		// {
		// 	name: '广告位',
		// 	path: '/advert',
		// 	parent: '内容管理'
		// }
	],
	[
		{
			name: '员工列表',
			path: '/yuangongList',
			parent: '员工管理'
		},
		{
			name: '角色列表',
			path: '/jueseList',
			parent: '员工管理'
		}
	],
	[
		{
			name: '医院列表',
			path: '/hospitalList',
			parent: '医院管理'
		},
		{
			name: '计费方式',
			path: '/charging',
			parent: '医院管理'
		}
	],
	[
		{
			name: '订单流水',
			path: '/orderFlowing',
			parent: '对账管理'
		},
		{
			name: '提现设置',
			path: '/withdrawalConfig',
			parent: '对账管理'
		},
		{
			name: '提现管理',
			path: '/withdrawalList',
			parent: '对账管理'
		},
		{
			name: '对账用户',
			path: '/account',
			parent: '对账管理'
		}
	],
	[
		{
			name: '固件管理',
			path: '/fireware',
			parent: '固件管理'
		},
		{
			name: '固件推送',
			path: '/firewarePush',
			parent: '固件管理'
		}
	],
	[
		{
			name: '物联网卡',
			path: '/iotcard',
			parent: '物联网卡'
		}
	],
]
let h5SidebarList = [
	{
		name: '设备清单',
		path: '/h5/deviceList',
		parent: 'h5'
	}, {
		name: '订单管理',
		path: '/h5/orderList',
		parent: 'h5'
	}
]
export { headerNav, sidebarList, sidebarList2, h5SidebarList }
